import "./App.scss";
import { useEffect } from "react";
import { useState, useCallback } from "react";
import { OpenSeaDragonViewer } from "./openseadragonviewer/openseadragonviewer";
import { StorySelectorModal } from "./story-selector-modal/story-selector-modal";
import { NowReading } from "./now-reading/now-reading";
import { useSearchParams } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { STORIES } from "./stories";
import { useShare } from "./use-share";

/* eslint-disable quotes */
const getSearchParamsObject = () => {
  if (!window.location.search) return {};
  var search = window.location.search.substring(1);
  return JSON.parse(
    '{"' +
      decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
};

const getStoryFromSearchParam = (params) => {
  const search = params.get("story");
  if (!search) return null;
  if (Object.keys(STORIES).includes(search)) {
    return search;
  }
  return null;
};

function App() {
  const [page, setPage] = useState(1);
  const [isStorySelectorShown, setIsStorySelectorShown] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedStory, setSelectedStory] = useState(() => {
    const storyFromParams = getStoryFromSearchParam(searchParams);
    return storyFromParams || "paib";
  });
  const { trackPageView, trackEvent } = useMatomo();
  const { shareShareable, isShareBusy } = useShare({
    shareable: STORIES?.[selectedStory]?.shareable || null,
    trackEvent,
    selectedStory,
  });

  useEffect(() => {
    trackPageView({
      documentTitle: "SUPA Reader",
      customDimensions: [
        {
          id: 1,
          value: searchParams.get("story") ? searchParams.get("story") : "",
        },
      ],
    });
  }, []);

  useEffect(() => {
    const page = parseInt(searchParams.get("page"));
    const story = searchParams.get("story");
    if (!page) return;

    if (story) {
      if (page > STORIES[story].pagecount) {
        setSearchParams({ ...getSearchParamsObject(), page: 1 });
        setPage(1);
        return;
      }
    } else if (selectedStory) {
      if (page > STORIES[selectedStory].pagecount) {
        setSearchParams({ ...getSearchParamsObject(), page: 1 });
        setPage(1);
        return;
      }
    }

    setPage(page);
  }, []);

  const nextPage = useCallback(() => {
    if (STORIES[selectedStory].pagecount === page) return;
    const newPage = page + 1;
    setPage(newPage);
    setSearchParams({ ...getSearchParamsObject(), page: newPage });
    trackEvent({
      name: "next-page",
      category: "page-nav",
      action: { story: selectedStory, page },
    });

    if (STORIES[selectedStory].pagecount === newPage) {
      trackEvent({
        name: "finished-story",
        category: "general",
        action: selectedStory,
      });
    }
  }, [page, selectedStory, page, selectedStory]);

  const prevPage = useCallback(() => {
    if (page === 1) return;
    const newPage = page - 1;
    setPage((curr) => curr - 1);
    setSearchParams({ ...getSearchParamsObject(), page: newPage });
    trackEvent({
      name: "prev-page",
      category: "page-nav",
      action: { story: selectedStory, page },
    });
  }, [page]);

  const showStorySelector = () => {
    setIsStorySelectorShown(true);
    trackEvent({
      name: "story-selector",
      category: "UI",
      action: "show",
    });
  };
  const hideStorySelector = () => {
    setIsStorySelectorShown(false);
    trackEvent({
      name: "story-selector",
      category: "UI",
      action: "hide",
    });
  };

  return (
    <div className="App">
      <div className="now-reading-wrapper">
        <NowReading story={selectedStory} />
      </div>
      <StorySelectorModal
        show={isStorySelectorShown}
        onHide={() => hideStorySelector()}
        onStorySelect={({ story }) => {
          setPage(1);
          setSelectedStory(story);
          setSearchParams({ story, page: 1 });
          hideStorySelector();
          trackEvent({
            name: "story-select",
            category: "general",
            action: story,
          });
        }}
        selectedStory={selectedStory}
      />
      <OpenSeaDragonViewer
        maxPage={STORIES[selectedStory].pagecount}
        currPage={page}
        onNextPage={nextPage}
        onPrevPage={prevPage}
        onStoriesClick={showStorySelector}
        image={`https://supa-testbucket.s3.eu-central-1.amazonaws.com/${selectedStory}-${page}.dzi`}
        url={`https://supa-testbucket.s3.eu-central-1.amazonaws.com/${selectedStory}-${page}_files/`}
        onShareClick={shareShareable}
        isShareBusy={isShareBusy}
        storyHasShareable={!!STORIES[selectedStory].shareable}
      />
    </div>
  );
}

export default App;
