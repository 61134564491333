import colaCover from "./story-selector/story-thumbs/cola-cover.jpg";
import emksaCover from "./story-selector/story-thumbs/emksa-cover.jpg";
import rawaCover from "./story-selector/story-thumbs/rawa-cover.jpg";
import bb4hCover from "./story-selector/story-thumbs/bb4h-cover.jpg";
import wszsCover from "./story-selector/story-thumbs/wszs-cover.jpg";
import mhcCover from "./story-selector/story-thumbs/mhc-cover.jpg";
import paibCover from "./story-selector/story-thumbs/paib-cover.jpg";

import colaShareable from "./images/shareables/COLA.jpg";
import emksaShareable from "./images/shareables/EMKSA.jpg";
import rawaShareable from "./images/shareables/RAWA.jpg";
import bb4hShareable from "./images/shareables/BB4H.jpg";
import wszsShareable from "./images/shareables/WSZS.jpg";
import mhcShareable from "./images/shareables/MHC.jpg";
import paibShareable from "./images/shareables/PAIB.jpg";


export const STORIES = {
  bb4h: {
    pagecount: 6,
    title: "Bros before Hoes",
    year: 2017,
    image: bb4hCover,
    shareable: bb4hShareable,
  },
  cola: {
    pagecount: 8,
    title: "Clout oder Liebe? Aaah",
    year: 2019,
    image: colaCover,
    shareable: colaShareable,
  },
  emksa: {
    pagecount: 15,
    title: "Ein Mord kommt selten Allein",
    year: 2020,
    image: emksaCover,
    shareable: emksaShareable,
  },
  rawa: {
    pagecount: 17,
    title: "Rock an, Welt aus!",
    year: 2021,
    image: rawaCover,
    shareable: rawaShareable,
  },
  wszs: {
    pagecount: 16,
    title: "Wenn zwei sich streiten",
    year: 2022,
    image: wszsCover,
    shareable: wszsShareable,
  },
  mhc: {
    pagecount: 14,
    title: "Murder House Challenge",
    year: 2023,
    image: mhcCover,
    shareable: mhcShareable,
  },
  paib: {
    pagecount: 15,
    title: "Phil allein in Berlin",
    year: 2025,
    image: paibCover,
    shareable: paibShareable,
  },
};
